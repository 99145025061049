import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
  SlideReveal,
  multipleComponentIterator,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"about-section-copy"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
            column={"2"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={8} textAlign={""}>
                <Image
                  size={"large"}
                  centered={false}
                  src={
                    "https://fisherman.gumlet.io/public/ecced18a-ad62-4442-a99f-d512a28adaf5/ra-about-cascade-1.jpeg"
                  }
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign={"left"}>
                <div>
                  <h2>Our True Passion Grew</h2>
                  <p>
                    As the beauty industry grew more demanding, Robert released
                    all but one of his nine salons, Robert Andrew
                    Hair-Skin-Nails. Here he focused on meeting the
                    ever-changing needs of his growing clientele.
                  </p>
                  <p>
                    Years later, Robert and his daughter Melissa—now President
                    and co-owner of Robert Andrew—realized the need to adapt
                    once again. Their facility simply could no longer
                    accommodate all of the needs of their clients, which
                    included more skin care and spa services, such as massages
                    and body treatments.
                  </p>
                  <p>
                    In 1996, after more than twenty years in business, Robert
                    Andrew Hair-Skin-Nails became the Robert Andrew Day Spa
                    Salon, a 7,000 square foot facility that could better
                    service the needs of their clientele. The expansion from
                    salon to day spa salon brought Robert Andrew unprecedented
                    success as one of the very first “true” day spa salons in
                    the United States. In 1997, Modern Salon Magazine honored
                    the Robert Andrew Day Spa Salon with one of the most
                    prestigious awards in the beauty industry –The 1997 Modern
                    Salon of the Year. The day spa salon was then named one of
                    the Top 200 Salons In North America by Salon Today Magazine
                    for four consecutive years, and Robert Andrew was fast on
                    its way to realizing the goal of becoming the premier day
                    spa experience on the East Coast.
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            className={"about-section-copy"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
            column={"2"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={8} textAlign={"left"}>
                <div>
                  <h2>Raising the Bar</h2>
                  <p>
                    In the summer of 2002, with the creation of their latest
                    state-of-the-art facility—Robert Andrew – The Salon &
                    Spa—Robert Andrew once again raised the bar for day spas in
                    the area. Centrally located between Washington D.C.,
                    Annapolis and Baltimore, Robert Andrew delivers the rich,
                    indulgent experience of a destination spa. It is the only
                    day spa salon of this caliber in the area, and is presently
                    one of the largest day spas in the country!
                  </p>
                  <p>
                    Fastforward more than a decade, Robert Andrew has
                    established a strong presence in the regional market and has
                    positioned themselves as the largest privately owned day spa
                    and salon in Spring 2014 with the addition of VIBE Salon,
                    The MenZone and a Medispa which in 2017 became a
                    Skinceuticals flagship skin clinic “dermaspa”. With these
                    added to the collection of salons and spas on the property,
                    Robert Andrew-The Collection of Salons and Spas now offers
                    guests 32,000 square feet of relaxation and rejuvenation.
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={8} textAlign={"center"}>
                <div>
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/ecced18a-ad62-4442-a99f-d512a28adaf5/50year-seal.png"
                    }
                  />
                </div>
                <div>
                  <Image
                    size={"large"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/ecced18a-ad62-4442-a99f-d512a28adaf5/Pictures-19-e1559888021945.jpeg"
                    }
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"about-staff-container"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                as={"h2"}
                className={"about-staff-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "OUR TOP STYLISTS",
                })}
              />
              <MarkdownContent
                className={"about-staff-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
              <Card.Group
                className={"about-featured-staff stylists"}
                itemsPerRow={2}
              >
                <SlideReveal
                  cascade={true}
                  triggerOnce={true}
                  className={"ui card"}
                  fade={true}
                  direction={"up"}
                  children={multipleComponentIterator({
                    iterator: allFishermanBusinessTeam.nodes,
                    limit: 2,
                    components: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          key: "_id",
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  })}
                />
              </Card.Group>
              <Button to={"/team/"} primary={true} as={InternalLink}>
                See All Stylists
              </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
